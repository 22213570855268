import Highway from '@dogstudio/highway';
import { manager } from '../manager/Manager';
import Project from '../components/Project';

class ProjectRenderer extends Highway.Renderer {
  // Hooks/methods
    onEnter() {
      console.log("on enter");
      manager.hideNav();
      setTimeout(() => {
        manager.reloadProject();
        manager.project.disappear();
      }, 200);
      
    }
    onLeave() {
      console.log("on leave");
      manager.oldProject = manager.project;
      manager.oldProject.hide();
      manager.showNav();
    }
    onEnterCompleted() {
      console.log("on enter c");
      setTimeout(() => {
        manager.project.show();
        [...document.querySelectorAll('[data-hover]')].forEach((link) => {
        link.addEventListener('mouseenter', () => manager.cursor.enter() );
        link.addEventListener('mouseleave', () => manager.cursor.leave() );
        link.addEventListener('click', () => manager.cursor.click() );

        });
      }, 200);
      
    }
    onLeaveCompleted() {
      console.log("on leave c");
    }
}

// Don`t forget to export your renderer
export default ProjectRenderer;