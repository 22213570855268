import Figure from './Figure';
import TiltFx from '../effects/TiltFX';
import charming from 'charming';
import { manager } from '../manager/Manager';
import {utils} from '../utils/Utils';

class Slide {
    constructor(el) {
        this.DOM = {el: el};
        this.figures = [];
        [...this.DOM.el.querySelectorAll('.slide__figure')].forEach(figure => this.figures.push(new Figure(figure, true)));
        this.figuresTotal = this.figures.length;
        this.DOM.title = this.DOM.el.querySelector('.slide__title');
        this.DOM.content = this.DOM.el.querySelector('.slide__content');
        this.DOM.infos = this.DOM.el.querySelector('.slide__infos');
        this.contentcolor = this.DOM.el.dataset.contentcolor;
        // We will add a tilt effect for the title. For this we will create clones of the title that will move 
        // when the user moves the mouse.
        // Number of total title elements;
        this.totalTiltText = 1;
        this.DOM.innerTitleTmp = document.createElement('span');
        this.DOM.innerTitleTmp.className = 'slide__title-inner';
        this.DOM.innerTitleTmp.innerHTML = this.DOM.title.innerHTML;
        this.DOM.title.innerHTML = '';
        for (let i = 0; i <= this.totalTiltText-1; ++i) {
            this.DOM.title.appendChild(this.DOM.innerTitleTmp.cloneNode(true));
        }
        this.DOM.innerTitle = [...this.DOM.title.querySelectorAll('.slide__title-inner')];
        // Split the title inner elements into spans using charmingjs.
        this.DOM.innerTitle.forEach((inner) => charming(inner));
        this.innerTitleTotal = this.DOM.innerTitle.length;
        // Letters of the main one (the top most inner title).
        this.innerTitleMainLetters = [...this.DOM.innerTitle[this.innerTitleTotal-1].querySelectorAll('span')];
        // total letters.
        this.titleLettersTotal = this.innerTitleMainLetters.length;
        // Initialize the tilt effect for the title.
        this.textTilt = new TiltFx(false, this.DOM.title);
        this.DOM.text = this.DOM.el.querySelector('.slide__text');
    }
    setCurrent() {
        this.toggleCurrent(true);
    }
    setFirstCurrent() {

       
        const currentSlide = this;
        var times = {};
        times.switchtime = 0.5;
        this.toggleCurrent(true);

        var action = 'show';
        var dir = 'up';

        var tl = new TimelineMax({
            onComplete: () => {
            
            }
        }).add('begin');

    
        var currentSlideFigures = currentSlide.figures.sort((a,b) => a.DOM.el.dataset.sort-b.DOM.el.dataset.sort);
        var figureMain = currentSlideFigures.find(figure => figure.isMain);
        var figureSecond = currentSlideFigures.find(figure => figure.isSecond);
        var figureThird = currentSlideFigures.find(figure => figure.isThird);

        
        times.slideFigures = pos => Number(times.switchtime + pos*0.13);

        times.texts = 0.5;

        tl.to(currentSlide.DOM.text, 0.8, { 
            ease: Quint.easeOut,
            opacity: 1
        }, 'begin+=' + times.texts);

        times.content = 0.5;
        // Content comes/goes now..
        tl
        .to(figureMain.DOM.el, 0.8, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '-101%' : '101%'
        }, 'begin+=' + times.content)
        .to(figureMain.DOM.slideEl, 0.8, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%',
        }, 'begin+=' + times.content)
        .to(figureSecond.DOM.el, 0.8, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%'
        }, 'begin+=' + times.content)
        .to(figureThird.DOM.el, 0.8, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%'
        }, 'begin+=' + times.content)

        .staggerTo(utils.shuffleArray(currentSlide.innerTitleMainLetters), 0.05, { 
            ease: Quint.easeOut,
            opacity: action === 'show' ? 1 : 0
        }, 0.04, 'begin+=' + times.content);

    }
    unsetCurrent() {
        this.toggleCurrent(false);
    }
    toggleCurrent(isCurrent) {
        this.DOM.el.classList[isCurrent ? 'add' : 'remove']('slide--current');
        // Start/Stop the images tilt effect (initialized on the main figure).
        this.figures.find(figure => figure.isMain).tilt[isCurrent ? 'start' : 'stop']();
        // Start/Stop the title tilt effect.
        this.textTilt[isCurrent ? 'start' : 'stop']();
    }
    disappear(){
        var action = 'hide';
        var dir = 'down';
        const currentSlide = this;


        var tl = new TimelineMax({
            onComplete: () => {
            }
        }).add('begin');

        var times = {};
        times.switchtime = 0;

        var currentSlideFigures = currentSlide.figures.sort((a,b) => a.DOM.el.dataset.sort-b.DOM.el.dataset.sort);
        var figureMain = currentSlideFigures.find(figure => figure.isMain);
        var figureSecond = currentSlideFigures.find(figure => figure.isSecond);
        var figureThird = currentSlideFigures.find(figure => figure.isThird);

        times.slideFigures = pos => Number(times.switchtime + pos*0.13);

        times.texts = 0;

        tl.to(currentSlide.DOM.text, 0, { 
            ease: Quint.easeOut,
            opacity: 0
        }, 'begin+=' + times.texts);

        times.content = 0;
        // Content comes/goes now..
        tl
        .to(figureMain.DOM.el, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '-101%' : '101%'
        }, 'begin+=' + times.content)
        .to(figureMain.DOM.slideEl, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%',
        }, 'begin+=' + times.content)
        .to(figureSecond.DOM.el, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%'
        }, 'begin+=' + times.content)
        .to(figureThird.DOM.el, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '-101%' : '101%'
        }, 'begin+=' + times.content)
        
        .staggerTo(utils.shuffleArray(currentSlide.innerTitleMainLetters), 0, { 
            ease: Quint.easeOut,
            opacity: action === 'show' ? 1 : 0
        }, 0, 'begin+=' + times.content);
    }
}

export default Slide;