import Highway from '@dogstudio/highway';
import Tween from 'gsap';
import {manager} from '../manager/Manager';
import {utils} from '../utils/Utils';

// Fade
class HomeTransition extends Highway.Transition {
    
    in({ from, to, done }) {
       
        // Reset Scroll
        window.scrollTo(0, 0);
    
        // Remove Old View
        from.remove();
    
        // Animation
        
        Tween.fromTo(to, 0.3,
            { opacity: 0 },
            {
              opacity: 1,
              onComplete: done
            }
        );
        
    }
  
    out({ from, done }) {
      
        Tween.fromTo(from, 0.5,
            { opacity: 1 },
            {
              opacity: 0,
              onComplete: done
            }
          );

    }
}
  
export default HomeTransition;