class Utils {
    constructor() {
        this.shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
        this.lineEq = (y2, y1, x2, x1, currentVal) => {
            let m = (y2 - y1) / (x2 - x1); 
            let b = y1 - m * x1;
            return m * currentVal + b;
        };
        this.lerp = (a, b, n) => (1 - n) * a + n * b;
        this.body = document.body;
        this.bodyColor = getComputedStyle(this.body).getPropertyValue('--color-bg').trim() || 'white';
        this.getMousePos = (e) => {
            let posx = 0;
            let posy = 0;
            if (!e) e = window.event;
            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            }
            else if (e.clientX || e.clientY) 	{
                posx = e.clientX + this.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + this.body.scrollTop + document.documentElement.scrollTop;
            }
            return { x : posx, y : posy }
        }
        this.winsize = null;
        this.calcWinsize = () => this.winsize = {width: window.innerWidth, height: window.innerHeight};
        window.addEventListener('resize', this.calcWinsize);
        this.init();
    }

    init(){
        this.calcWinsize();
    }
}
export let utils = new Utils();
