import Cursor from '../components/Cursor';
import Menu from '../components/Menu';
import Navigation from '../components/Navigation';
import Slideshow from '../components/Slideshow';
import Project from '../components/Project';

class Manager{
    constructor(){
        this.cursor = null;
        this.menu = null;
        this.navigation = null;
        this.slideshow = null;
        this.currentSlideOnHome = 0;
        this.project = null;
        this.oldProject = null;
    }

    initCursor(){
        if(!this.cursor){
            this.cursor = new Cursor(document.querySelector('.cursor'));
        }
    }

    initMenu(){
        if(!this.menu){
            this.menu = new Menu(document.querySelector('nav.menu'));
        }
    }

    initSlideshow(index){
        if(!this.slideshow){
            this.slideshow = new Slideshow(document.querySelector('.slideshow'), index);
        }
    }

    initNavigation(){
        if(!this.navigation){
            this.navigation = new Navigation(document.querySelector('.nav'));
            this.slideshow.nav = this.navigation;
        }
    }

    initProject(){
        if(!this.project){
            this.project = new Project(document.querySelector('.slide--open'));
        }
    }

    reloadSlideshow(index){
        this.slideshow = null;
        this.slideshow = new Slideshow(document.querySelector('.slideshow'), index);
    }
    reloadNavigation(){
        this.navigation = null;
        this.navigation = new Navigation(document.querySelector('.nav'));
        this.slideshow.nav = this.navigation;
    }

    reloadProject(){
        this.project = null;
        this.project = new Project(document.querySelector('.slide--open'));
        console.log(this.project);
    }

    hideNav(){
        var nav = document.querySelector('.nav');
        nav.style.display = "none";
    }

    showNav(){
        var nav = document.querySelector('.nav');
        nav.style.display = "block";
    }

    changeCurrentIndex(index){
        console.log(index);
        this.currentSlideOnHome = index;
    }
}

export let manager = new Manager();