import Highway from '@dogstudio/highway';
import Tween from 'gsap';
import {manager} from '../manager/Manager';
import {utils} from '../utils/Utils';
import Project from '../components/Project';

// Fade
class ProjectTransition extends Highway.Transition {
  in({ from, to, done }) {
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();
    
    Tween.fromTo(to, 0.3,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: done
      }
    );
       
  }

  out({ from, done }) {
    // Animation
    Tween.fromTo(from, 0.5,
      { opacity: 1 },
      {
        opacity: 0,
        onComplete: done
      }
    );
  }
}

export default ProjectTransition;