import Highway from '@dogstudio/highway';
import HomeTransition from './transitions/HomeTransition';
import ProjectTransition from './transitions/ProjectTransition';
import HomeRenderer from './renderers/HomeRenderer';
import ProjectRenderer from './renderers/ProjectRenderer';
import imagesLoaded from 'imagesloaded';

import {utils} from './utils/Utils';
import {manager} from './manager/Manager';



// Call Highway.Core once.
const H = new Highway.Core({
  transitions: {
    home: HomeTransition,
    project: ProjectTransition
  },
  renderers: {
    home: HomeRenderer,
    project: ProjectRenderer
  }
});

document.addEventListener("DOMContentLoaded", function(){
    manager.initCursor();
    manager.initMenu();
});

// Custom cursor chnages state when hovering on elements with 'data-hover'.
[...document.querySelectorAll('[data-hover]')].forEach((link) => {
    link.addEventListener('mouseenter', () => manager.cursor.enter() );
    link.addEventListener('mouseleave', () => manager.cursor.leave() );
    link.addEventListener('click', () => manager.cursor.click() );
});

// Preload all the images in the page.
imagesLoaded(document.querySelectorAll('.slide__figure-img'), {background: true}, () => utils.body.classList.remove('loading'));