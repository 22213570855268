import Figure from './Figure';
import TiltFx from '../effects/TiltFX';
import charming from 'charming';
import { manager } from '../manager/Manager';
import {utils} from '../utils/Utils';
import $ from 'jquery';
import Glide from '@glidejs/glide';

import imagesLoaded from 'imagesloaded';
import Unsplash, { toJson } from "unsplash-js";
import Masonry from 'masonry-layout';


class Project {
    constructor(el) {
        this.DOM = {el: el};
        this.figures = [];
        [...this.DOM.el.querySelectorAll('.slide__figure')].forEach(figure => this.figures.push(new Figure(figure, false)));
        this.figuresTotal = this.figures.length;
        this.DOM.title = this.DOM.el.querySelector('.slide__title');
        this.DOM.content = this.DOM.el.querySelector('.slide__content');
        this.DOM.infos = this.DOM.el.querySelector('.slide__infos');
        this.contentcolor = this.DOM.el.dataset.contentcolor;
        // We will add a tilt effect for the title. For this we will create clones of the title that will move 
        // when the user moves the mouse.
        // Number of total title elements;
        this.totalTiltText = 1;
        this.DOM.innerTitleTmp = document.createElement('span');
        this.DOM.innerTitleTmp.className = 'slide__title-inner';
        this.DOM.innerTitleTmp.innerHTML = this.DOM.title.innerHTML;
        this.DOM.title.innerHTML = '';
        for (let i = 0; i <= this.totalTiltText-1; ++i) {
            this.DOM.title.appendChild(this.DOM.innerTitleTmp.cloneNode(true));
        }
        this.DOM.innerTitle = [...this.DOM.title.querySelectorAll('.slide__title-inner')];
        // Split the title inner elements into spans using charmingjs.
        this.DOM.innerTitle.forEach((inner) => charming(inner));
        this.innerTitleTotal = this.DOM.innerTitle.length;
        // Letters of the main one (the top most inner title).
        this.innerTitleMainLetters = [...this.DOM.innerTitle[this.innerTitleTotal-1].querySelectorAll('span')];
        // total letters.
        this.titleLettersTotal = this.innerTitleMainLetters.length;
        // Initialize the tilt effect for the title.
        this.textTilt = new TiltFx(false, this.DOM.title);
        this.DOM.text = this.DOM.el.querySelector('.slide__text');

        var integer = parseInt(this.DOM.el.attributes[1].value, 10);
        manager.changeCurrentIndex(integer);

        document.querySelector('.goTop').addEventListener('click', function(){

            $('html, body').animate({
              scrollTop:0
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              console.log("finished scroll");
             return;
            });
          });

          var slidersBasic = document.body.querySelectorAll('.glideBasic');
        if (slidersBasic) {
          for (let index = 0; index < slidersBasic.length; index++) {
            var glideBasic = new Glide(slidersBasic[index], {
              focusAt: 'center',
              perView: 1,
              peek:200,
            });
            glideBasic.mount();
          }
        }
        
        var slidersFull = document.body.querySelectorAll('.glideFull');
        if (slidersFull) {
          for (let index = 0; index < slidersFull.length; index++) {
            var glideFull = new Glide(slidersFull[index], {
              focusAt: 0,
              perView: 1,
              peek:100
            });
            glideFull.mount();
          }
        }        

        var slidersMockup = document.body.querySelectorAll('.glideMockup');
        if (slidersMockup) {
          for (let index = 0; index < slidersMockup.length; index++) {
            var glideMockup = new Glide(slidersMockup[index], {
              focusAt: 'center',
              perView: 1,
              peek:300
            });
            glideMockup.mount();
          }
        }


        const Unsplash = require('unsplash-js').default;
        const unsplash = new Unsplash({
          accessKey: "H_nyjWukj4GAzZuwSqZljc8_66ONI4oIIfaOIfcRrcY",
          secret: "OlEvs9T62KAHvCutdcn5vs7fH0nMsfFQdu0MMwl9EV0"
        });

        unsplash.users.photos("elodieoudot", 1, 20, "popular", false)
        .then(toJson)
        .then(json => {
          console.log("unsplash");
          console.log(json);

          for (let index = 0; index < json.length; index++) {
            const element = json[index];
            var item = "<div class='masonry__item js-masonry-item'><figure><picture><a href="+element.links.html+" target='_blank' data-hover><img src="+element.urls.full+" alt=''></a></picture></figure></div>"
            document.querySelector('.js-masonry').innerHTML += item;
            
          }

          imagesLoaded('.js-images-loaded', () => {
            new Masonry(document.querySelector('.js-masonry'), {
              itemSelector: '.js-masonry-item'
            });
          });

          [...document.querySelectorAll('[data-hover]')].forEach((link) => {
            link.addEventListener('mouseenter', () => manager.cursor.enter() );
            link.addEventListener('mouseleave', () => manager.cursor.leave() );
            link.addEventListener('click', () => manager.cursor.click() );
    
            });

        });


    }

     toggleCurrent(isCurrent) {
      console.log("toggle current", isCurrent);
        this.DOM.el.classList[isCurrent ? 'add' : 'remove']('slide--current');
        // Start/Stop the images tilt effect (initialized on the main figure).
        //this.figures.find(figure => figure.isMain).tilt[isCurrent ? 'start' : 'stop']();
        // Start/Stop the title tilt effect.
        this.textTilt[isCurrent ? 'start' : 'stop']();
    }

    show(){

      console.log("show");
        const currentSlide = this;
        document.body.classList.remove('open');
        var times = {};
        times.switchtime = 0.5;
        this.toggleCurrent(true);

        var action = 'show';
        var dir = 'up';
        document.body.classList.add('open');

        var tl = new TimelineMax({
            onComplete: () => {
            
            }
        }).add('begin');

        
        var currentSlideFigures = currentSlide.figures.sort((a,b) => a.DOM.el.dataset.sort-b.DOM.el.dataset.sort);
        var figureMain = currentSlideFigures.find(figure => figure.isMain);

        times.slideFigures = pos => Number(times.switchtime + pos*0.13);

        times.texts = 0.5;

        times.content = 0.5;
        // Content comes/goes now..
        
        tl
            .to(figureMain.DOM.el, 0.8, { 
                ease: Quint.easeOut,
                y: '0%'
            }, 'begin+=' + times.content)
            .to(figureMain.DOM.slideEl, 0.8, { 
                ease: Quint.easeOut,
                y: '0%',
            }, 'begin+=' + times.content)
            .to(currentSlide.DOM.content, 0.8, { 
                ease: Quint.easeOut,
                opacity: 1,
                startAt: action === 'show' ? {y: '5%'} : {},
                y: action === 'show' ? '0%' : '5%'
            }, 'begin+=' + times.content)
            .to(currentSlide.DOM.infos, 0.8, { 
                ease: Quint.easeOut,
                opacity: 1,
                startAt: action === 'show' ? {y: '5%'} : {},
                y: action === 'show' ? '0%' : '5%'
            }, 'begin+=' + times.content)
            
            .staggerTo(utils.shuffleArray(currentSlide.innerTitleMainLetters), 0.05, { 
                ease: Quint.easeOut,
                opacity: 1
            }, 0.04, 'begin+=' + times.content);

    }

    hide(){
      console.log("hide");
        const action = 'hide';
        var dir = 'down';
        document.body.classList.remove('open');


        var tl = new TimelineMax({
            onComplete: () => {
                if ( action === 'hide' ) {
                }
            }
        }).add('begin');

        const times = {};
        times.switchtime = 0;


        const currentSlideFigures = this.figures.sort((a,b) => a.DOM.el.dataset.sort-b.DOM.el.dataset.sort);
        const figureMain = currentSlideFigures.find(figure => figure.isMain);

        times.slideFigures = pos => Number(times.switchtime + pos*manager.slideshow.animationSettings.staggerFactor);

        times.texts = 0;

        times.content = 0;
        // Content comes/goes now..
        tl
        .to(figureMain.DOM.el, 0.8, { 
            ease: Quint.easeOut,
            y: '-101%'
        }, 'begin+=' + times.content)
        .to(figureMain.DOM.slideEl, 0.8, { 
            ease: Quint.easeOut,
            y:'101%',
        }, 'begin+=' + times.content)
        
        .staggerTo(utils.shuffleArray(this.innerTitleMainLetters), 0.05, { 
            ease: Quint.easeOut,
            opacity: action === 'show' ? 1 : 0
        }, 0.04, 'begin+=' + times.content);

    }

    disappear(){
      console.log("disappear");
        var action = 'hide';
        var dir = 'down';
        const currentSlide = this;
        document.body.classList.remove('open');


        var tl = new TimelineMax({
            onComplete: () => {
            }
        }).add('begin');

        var times = {};
        times.switchtime = 0;

       
        var currentSlideFigures = this.figures.sort((a,b) => a.DOM.el.dataset.sort-b.DOM.el.dataset.sort);
        var figureMain = currentSlideFigures.find(figure => figure.isMain);

        times.slideFigures = pos => Number(times.switchtime + pos*0.13);

        times.texts = 0;


        times.content = 0;
        // Content comes/goes now..
        tl
        .to(figureMain.DOM.el, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '-101%' : '101%'
        }, 'begin+=' + times.content)
        .to(figureMain.DOM.slideEl, 0, { 
            ease: Quint.easeOut,
            y: action === 'show' ? '0%' : dir === 'up' ? '101%' : '-101%',
        }, 'begin+=' + times.content)
        
        .staggerTo(utils.shuffleArray(this.innerTitleMainLetters), 0, { 
            ease: Quint.easeOut,
            opacity: action === 'show' ? 1 : 0
        }, 0, 'begin+=' + times.content);
    }
}

export default Project;