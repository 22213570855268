import Highway from '@dogstudio/highway';
import { manager } from '../manager/Manager';

class HomeRenderer extends Highway.Renderer {
  // Hooks/methods
    onEnter() {
        manager.reloadSlideshow(0);
        manager.reloadNavigation();
        manager.slideshow.showSlide(manager.currentSlideOnHome);
        manager.showNav();
        [...document.querySelectorAll('[data-hover]')].forEach((link) => {
            link.addEventListener('mouseenter', () => manager.cursor.enter() );
            link.addEventListener('mouseleave', () => manager.cursor.leave() );
            link.addEventListener('click', () => manager.cursor.click() );
        });
    }
    onLeave() {
        manager.slideshow.hideSlide();
        manager.hideNav();
    }
    onEnterCompleted() {
    }
    onLeaveCompleted() {
    }
}

// Don`t forget to export your renderer
export default HomeRenderer;